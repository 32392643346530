export default {
  token: {
    colorPrimary: "#547756",
  },
  components: {
    Button: {
      borderRadiusLG: 4,
    },
    Input: {
      borderRadiusLG: 4,
    },
    Alert: {
      paddingMD: 8,
      borderRadiusLG: 4,
      fontSizeLG: 14,
      fontSize: 14,
      colorSuccessBg: "#F5FAF2",
      colorSuccessBorder: "#54AD27",
    },
  },
};
