/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  .layoutBody {
    margin-top: 56px;
    margin-left: 240px;
    width: calc(100vw - 240px);
    height: calc(100vh - 56px);
    overflow: auto;
  }
`;
