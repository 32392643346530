import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "./style";
import { setUserInfo } from "../../../store/features/UserInfo";
import { Button, Form, Input, Space, Tag, Modal, message } from "antd";
import ReactRouterPrompt from "react-router-prompt";
import SelectTags from "../../../components/SelectTags/index";
import MockPhone from "../../../components/MockPhone/index";
import PutUpload from "../../../components/PutUpload/index";
import UserDefaut from "../../../assets/User.png";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
const { TextArea } = Input;

const LIST_EXPERTISES = gql`
  query listExpertises {
    expertises {
      _id
      title
      backgroundColor
      linearColor
      backgroundUrl
      description
    }
  }
`;

const UPDATE_COACH = gql`
  mutation updateCoach($updateCoachInput: UpdateCoachInput!) {
    updateCoach(updateCoachInput: $updateCoachInput) {
      _id
    }
  }
`;

export default function ProfileBuilder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tempAvatar, setTempAvatar] = useState("");
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const [selectedTags, setSelectedTags] = useState([]);
  const [expertiseList, setExpertiseList] = useState([]);
  const [getExpertises, { data }] = useLazyQuery(LIST_EXPERTISES);

  useEffect(() => {
    if (userInfo.coach && userInfo.coach.profile_image) {
      setTempAvatar(userInfo.coach.profile_image);
    }
  }, []);

  useEffect(() => {
    if (loading) {
      setIsChanged(false);
    }
  }, [loading]);

  useEffect(() => {
    getExpertises();
    if (data) {
      const list = data.expertises.map((v) => {
        return {
          value: v._id,
          label: v.title,
          backgroundColor: v.backgroundColor,
          linearColor: v.linearColor,
        };
      });
      setExpertiseList(list);
    }
  }, [data, getExpertises]);
  const onSelectTags = (val) => {
    setSelectedTags(val);
  };

  const [updateCoach] = useMutation(UPDATE_COACH);

  // Upload
  const onUpload = (val) => {
    setTempAvatar(val);
  };

  // Form
  const [form] = Form.useForm();
  const nameValue = Form.useWatch("name", form);
  const beliefValue = Form.useWatch("belief", form);
  const expertiseValue = Form.useWatch("expertise", form);
  const aboutValue = Form.useWatch("about", form);
  const experienceValue = Form.useWatch("experience", form);

  const onBack = () => {
    navigate("/ProfileBuilder");
  };

  const onFormChange = () => {
    setIsChanged(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const param = {
        _id: userInfo.coach?._id,
        name: values.name,
        belief: values.belief,
        profile_image: tempAvatar,
        expertise: values.expertise.map((v) => v.value),
        about: values.about,
        experience: values.experience,
      };
      await updateCoach({
        variables: {
          updateCoachInput: param,
        },
      });
      const storeParam = { user: userInfo.user, coach: param };
      dispatch(setUserInfo(storeParam));
      message.success("My profile has been updated");
      navigate("/ProfileBuilder");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  return (
    <Container forceRender>
      <ReactRouterPrompt when={isChanged}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <Modal
              title="Unsaved changes"
              open
              footer={[
                <div key="flex-between" className="flex-between">
                  <Button
                    key="discard"
                    danger
                    loading={loading}
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    Discard Changes
                  </Button>
                  <div key="div">
                    <Button key="back" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      key="save"
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        onFinish();
                        onConfirm();
                      }}
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>,
              ]}
            >
              <p>
                You are about to leave this page with unsaved changes. Would you
                like to save these changes before leaving?
              </p>
            </Modal>
          )
        }
      </ReactRouterPrompt>
      <div className="left-side">
        <div className="page-title">Edit my profile</div>
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          size="large"
          className="full-width"
          initialValues={userInfo.coach}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormChange}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your name" }]}
          >
            <Input placeholder="Input your name" />
          </Form.Item>
          <Form.Item label="Headline" name="belief">
            <Input showCount maxLength={50} placeholder="Input your headline" />
          </Form.Item>
          <Form.Item label="Upload profile image" name="profile_image">
            <PutUpload
              fileName={`${
                userInfo.coach?._id
              }_${+new Date()}_profile_image.png`}
              onChange={onUpload}
            ></PutUpload>
          </Form.Item>
          <Form.Item
            label="Expertise (Please select max 3 items)"
            name="expertise"
            rules={[
              { required: true, message: "Please select your expertises" },
            ]}
          >
            <SelectTags
              initialValues={userInfo.coach?.expertise}
              options={expertiseList}
              onChange={onSelectTags}
            />
          </Form.Item>
          <Form.Item
            label="About"
            name="about"
            rules={[{ required: true, message: "Please input about" }]}
          >
            <TextArea
              showCount
              maxLength={200}
              placeholder="Input about"
              style={{ resize: "none" }}
            />
          </Form.Item>
          <Form.Item label="Experience" name="experience">
            <TextArea
              showCount
              maxLength={200}
              placeholder="Input your experience"
              style={{ resize: "none" }}
            />
          </Form.Item>

          <Form.Item>
            <Space size="middle">
              <Button type="primary" htmlType="submit" loading={loading}>
                Save and publish
              </Button>
              <Button onClick={onBack}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      <div className="right-side">
        <MockPhone title={nameValue || "Name"} showTabbar>
          <div className="mock-top">
            <img
              src={tempAvatar || UserDefaut}
              alt="profile_image"
              className="mock-avatar"
            />
            <div className="mock-headline">{beliefValue || "Headline"}</div>
          </div>
          <div className="mock-form">
            <div className="mock-form-item">
              <div className="mock-item-title">Expertise</div>
              <div className={`mock-item-value ${!expertiseValue && "c-gray"}`}>
                {(expertiseValue &&
                  expertiseValue.map((v, i) => {
                    return (
                      <Tag key={i} color={v.backgroundColor} className="mb-10">
                        {v.label}
                      </Tag>
                    );
                  })) ||
                  "Empty"}
              </div>
            </div>
            <div className="mock-form-item">
              <div className="mock-item-title">About</div>
              <pre className={`mock-item-value ${!aboutValue && "c-gray"}`}>
                {aboutValue || "Empty"}
              </pre>
            </div>
            <div className="mock-form-item">
              <div className="mock-item-title">Experience</div>
              <pre
                className={`mock-item-value ${!experienceValue && "c-gray"}`}
              >
                {experienceValue || "Empty"}
              </pre>
            </div>
            <div className="mock-form-item">
              <div className="mock-item-title">
                Practices
                <div className="right c-primary fs-13">More</div>
              </div>
              <div className="mock-item-value c-gray">Empty</div>
            </div>
          </div>
        </MockPhone>
      </div>
    </Container>
  );
}
