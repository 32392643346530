import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentPage,
  setPages,
  setEditData,
} from "../../../../store/features/CreatePractice";
import { Container } from "../style";
import { Button, Form, Input, Select, Row, Col, Progress } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import MockPhone from "../../../../components/MockPhone/index";
const { TextArea } = Input;

export default function EditForm({ onBack, onNextPage }) {
  const summaryData = useSelector((state) => state.practice.summaryData);
  const editData = useSelector((state) => state.practice.editData);
  const pages = useSelector((state) => state.practice.pages);
  const dispatch = useDispatch();
  const rowData = useSelector((state) => state.practice.practiceData);
  const paramId = rowData?._id;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (form && editData) {
      let initData = { ...editData };
      if (editData?.extraData?.includes("duration")) {
        initData.duration = JSON.parse(editData.extraData).duration;
      }
      form.setFieldsValue(initData);
    }
  }, [editData]);

  // Form
  const [form] = Form.useForm();
  const durationValue = Form.useWatch("duration", form);
  const titleValue = Form.useWatch("title", form);
  const descriptionBody = Form.useWatch("description", form);
  let initialForm = {
    _id: editData?._id,
    duration: editData?.duration || "5",
    title: summaryData.name,
    description: editData.description || "",
  };

  const onClickNext = () => {
    dispatch(setCurrentPage(2));
    dispatch(
      setEditData(
        Object.assign({}, initialForm, {
          duration: durationValue,
          description: descriptionBody,
        })
      )
    );
    onNextPage();
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);
    dispatch(setEditData(values));
    if (pages < 2) {
      dispatch(setPages(2));
    }
    dispatch(setCurrentPage(2));
    onNextPage();
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const toSummary = () => {
    dispatch(
      setEditData(
        Object.assign({}, initialForm, {
          duration: durationValue,
          description: descriptionBody,
        })
      )
    );
    onBack();
  };

  return (
    <Container>
      <div className="page-form">
        <div className="left-side">
          <div className="page-title">Edit Practice</div>
          {/* <div className="nav-line flex-around">
            <Button icon={<CaretLeftOutlined />} type="text" disabled></Button>
            <div className="text-center">Page1</div>
            <Button
              icon={<CaretRightOutlined />}
              type="text"
              disabled={pages < 2}
              onClick={onClickNext}
            ></Button>
          </div> */}
          <Form
            form={form}
            name="editForm"
            layout="vertical"
            size="large"
            className="full-width"
            initialValues={initialForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="How long will this practice take?"
              name="duration"
            >
              <Select
                placeholder="Select time"
                options={[
                  {
                    value: "1",
                    label: "1 minute",
                  },
                  {
                    value: "3",
                    label: "3 minutes",
                  },
                  {
                    value: "5",
                    label: "5 minutes",
                  },
                  {
                    value: "8",
                    label: "8 minutes",
                  },
                  {
                    value: "10",
                    label: "10 minutes",
                  },
                  {
                    value: "15",
                    label: "15 minutes",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Practice name"
              name="title"
              rules={[
                { required: true, message: "Please input practice name" },
              ]}
            >
              <TextArea
                showCount
                maxLength={100}
                placeholder="Input practice name"
                style={{ resize: "none" }}
                disabled
              />
            </Form.Item>
            <Form.Item
              label="Body"
              name="description"
              rules={[
                { required: true, message: "Please input practice body" },
              ]}
            >
              <TextArea
                showCount
                maxLength={300}
                placeholder="Input practice body"
                style={{ resize: "none" }}
              />
            </Form.Item>
            <br />
            <Form.Item>
              <Row gutter={16}>
                <Col span={8}>
                  <Button block disabled>
                    Prev page
                  </Button>
                </Col>
                <Col span={8}>
                  <Button block disabled={pages < 2} onClick={onClickNext}>
                    Next page
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    loading={loading}
                    disabled={paramId}
                  >
                    Add page
                  </Button>
                </Col>
              </Row>
              <Row gutter={16} className="mt-16">
                <Col span={12}>
                  <Button block onClick={toSummary}>
                    Back to Summary
                  </Button>
                </Col>
                <Col span={12}>
                  <Button block ghost type="primary" disabled>
                    Go publish
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className="right-side">
          <MockPhone
            title={(summaryData && summaryData.name) || "Practice Name"}
          >
            <Progress
              className="mock-progress"
              percent={10}
              showInfo={false}
              strokeLinecap="square"
              strokeColor={"#547756"}
            />
            <div className="time-hint">
              <p>Welcome to this exercise.</p>
              <p>
                This exercise will take around {durationValue || "0"} minutes
              </p>
            </div>
            <div className="edit-title">{titleValue || "Title"}</div>
            <pre className="edit-body">{descriptionBody || "Body"}</pre>
            <Button block type="primary" size="large">
              Next
            </Button>
          </MockPhone>
        </div>
      </div>
    </Container>
  );
}
