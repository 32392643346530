import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container } from "./style";
import { Button, Space, Steps, Modal } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  setPracticeData,
  setInitState,
  setPages,
  setCurrentPage,
  setSummaryData,
  setEditData,
  setEditAddData,
} from "../../../store/features/CreatePractice";
import { gql, useLazyQuery } from "@apollo/client";
import ReactRouterPrompt from "react-router-prompt";
import SummaryPage from "./components/SummaryPage";
import EditPage from "./components/EditPage";
import EditAddPage from "./components/EditAddPage";
import WrapPage from "./components/WrapPage";

const LIST_QUESTIONS = gql`
  query listQuestions($filters: ListQuestionInput) {
    questions(filters: $filters) {
      _id
      description
      order
      extraData
      placeholder
      practiceId
      title
      type
    }
  }
`;

const layoutList = {
  TEXT_ONLY: "desc",
  ONE_QUESTION_ONE_INPUT_TO_ANSWER: "short",
  ONE_QUESTION_MANY_OPTION_TO_SELECT: "multi",
};

export default function CreatePractice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [currentStep, setCurrentStep] = useState("summary");
  const location = useLocation();
  const rowData = useSelector((state) => state.practice.practiceData);
  const practiceId = rowData?._id;
  useEffect(() => {
    if (location.pathname === "/CreatePractice" && rowData._id) {
      dispatch(setPracticeData({}));
      rowData = {};
    }
    if (location.pathname === "/EditPractice" && !rowData._id) {
      navigate("/CreatePractice");
    }
  }, [location]);
  useEffect(() => {
    if (practiceId) {
      listQuestions();
    } else {
      dispatch(setInitState());
    }
  }, [practiceId]);

  useEffect(() => {
    if (rowData?._id) {
      dispatch(
        setSummaryData(
          Object.assign({}, rowData, {
            expertise: rowData.expertises.map((v) => v._id),
          })
        )
      );
    } else {
      setSummaryData({});
    }
  }, [rowData]);

  // Get questions data
  const [listQuestions, { data: questionsRes }] = useLazyQuery(LIST_QUESTIONS, {
    variables: {
      filters: { practiceId },
    },
  });
  useEffect(() => {
    if (questionsRes?.questions?.length > 0) {
      const list = questionsRes.questions;
      dispatch(setPages(list.length));
      dispatch(setEditData(list[0]));
      list.map((item, i) => {
        if (i > 0) {
          dispatch(setCurrentPage(i + 1));
          dispatch(
            setEditAddData({
              type: "update",
              formData: Object.assign({}, item, {
                layout: layoutList[item.type],
              }),
            })
          );
        }
      });
    } else {
      dispatch(setPages(1));
      dispatch(setEditData({}));
      dispatch(setEditAddData({}));
    }
  }, [questionsRes]);

  useEffect(() => {
    if (loading) {
      setIsChanged(false);
    }
  }, [loading]);

  const onBack = () => {
    navigate("/ContentManager");
    dispatch(setInitState());
  };
  const getCurrentStep = () => {
    if (currentStep === "summary") {
      return 0;
    }
    if (["edit", "editAdd"].includes(currentStep)) {
      return 1;
    }
    if (currentStep === "wrap") {
      return 2;
    }
  };
  const onDraft = () => {};

  // Summary
  const getIsChanged = (val) => {
    setIsChanged(val);
  };
  const onSummarySubmit = () => {
    setCurrentStep("edit");
  };

  // Edit
  const onEditBack = () => {
    setCurrentStep("summary");
  };
  const onEditAdd = () => {
    setCurrentStep("editAdd");
  };

  // Edit Add
  const onEditAddBack = () => {
    setCurrentStep("edit");
  };
  const onEditAddComplete = () => {
    setCurrentStep("wrap");
  };

  // Wrap
  const onWrapBack = () => {
    setCurrentStep("editAdd");
  };
  const onWrapComplete = () => {
    setIsChanged(false);
    setTimeout(() => {
      onBack();
    }, 1);
  };

  return (
    <Container forceRender>
      <ReactRouterPrompt when={isChanged}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <Modal
              title="Unsaved changes"
              open
              footer={[
                <div key="flex-between" className="flex-between">
                  <Button
                    key="discard"
                    danger
                    loading={loading}
                    onClick={() => {
                      setIsChanged(false);
                      onConfirm();
                    }}
                  >
                    Discard Changes
                  </Button>
                  <div key="div">
                    <Button key="back" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      key="save"
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        onDraft();
                        onConfirm();
                      }}
                    >
                      Save Draft
                    </Button>
                  </div>
                </div>,
              ]}
            >
              <p>
                You are about to leave this page with unsaved changes. Would you
                like to save these changes before leaving?
              </p>
            </Modal>
          )
        }
      </ReactRouterPrompt>
      <div className="page-top">
        <Steps
          current={getCurrentStep()}
          items={[
            {
              title: "Summary",
            },
            {
              title: "Edit Practice",
            },
            {
              title: "Wrap Up Practice",
            },
          ]}
        />
        {/* <Space size="middle">
          <Button size="large" disabled={!isChanged} onClick={onDraft}>
            Save as a draft
          </Button>
          <Button
            size="large"
            type="primary"
            loading={loading}
            disabled={!["wrap"].includes(currentStep)}
          >
            Publish
          </Button>
        </Space> */}
      </div>
      <Button
        className="btn-back"
        icon={<LeftOutlined />}
        type="text"
        onClick={onBack}
      >
        Content Manager
      </Button>

      {currentStep === "summary" && (
        <SummaryPage
          getIsChanged={getIsChanged}
          onBack={onBack}
          onSubmit={onSummarySubmit}
        />
      )}
      {currentStep === "edit" && (
        <EditPage onBack={onEditBack} onNextPage={onEditAdd} />
      )}
      {currentStep === "editAdd" && (
        <EditAddPage onBack={onEditAddBack} onComplete={onEditAddComplete} />
      )}
      {currentStep === "wrap" && (
        <WrapPage onBack={onWrapBack} onComplete={onWrapComplete} />
      )}
    </Container>
  );
}
