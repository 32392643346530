/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1600px;
  width: calc(100vw);
  height: calc(100vh);
  margin-left: -240px;
  margin-top: -56px;

  .bg-u {
    position: fixed;
    height: calc(100vh);
    width: calc(80.6vh);
    z-index: -1;
    top: 0;
    left: 0;
  }

  .loginPage {
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-around;
    color: ${({ theme }) => theme.primaryDark};
  }

  .loginLogo {
    line-height: 150px;
    .logo1 {
      font-size: 150px;
      font-weight: bold;
    }
    .logo2 {
      font-size: 150px;
      font-weight: lighter;
    }
  }
  .loginBox {
    background-color: #fff;
    width: 400px;
    min-height: 400px;
    padding: 32px;
    .loginBoxTitle {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .forget-pwd {
      margin-top: -6px;
      margin-bottom: 24px;
    }
  }
`;
