import { createSlice } from "@reduxjs/toolkit";

const localPractice = localStorage.getItem("uvita-practice");
export const practiceSlice = createSlice({
  name: "practice",
  initialState: {
    practiceData: (localPractice && JSON.parse(localPractice)) || {},
    currentPage: 1,
    pages: 1,
    summaryData: {},
    editData: {},
    editAddData: [],
  },
  reducers: {
    setPracticeData: (state, action) => {
      state.practiceData = action.payload;
      localStorage.setItem(
        "uvita-practice",
        JSON.stringify(action.payload) || ""
      );
    },
    setInitState: (state, action) => {
      state.currentPage = 1;
      state.pages = 1;
      state.summaryData = {};
      state.editData = {};
      state.editAddData = {};
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setSummaryData: (state, action) => {
      state.summaryData = action.payload;
    },
    setEditData: (state, action) => {
      state.editData = action.payload;
    },
    setEditAddData: (state, action) => {
      if (!action.payload.type) {
        state.editAddData = [];
      }
      if (action.payload.type === "add") {
        state.pages += 1;
        state.currentPage += 1;
        state.editAddData.splice(
          state.currentPage - 2,
          0,
          // action.payload.formData
          { layout: "desc" }
        );
      }
      if (action.payload.type === "update") {
        state.editAddData[state.currentPage - 2] = action.payload.formData;
      }
      if (action.payload.type === "delete") {
        state.editAddData = state.editAddData.filter(
          (v, i) => i !== state.currentPage - 2
        );
        state.pages -= 1;
        state.currentPage -= 1;
      }
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const {
  setPracticeData,
  setInitState,
  setCurrentPage,
  setPages,
  setSummaryData,
  setEditData,
  setEditAddData,
  setWrapData,
} = practiceSlice.actions;

export default practiceSlice.reducer;
