/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  .mock-phone {
    width: 412px;
    height: 816px;
    position: relative;
    border: 18px solid #3d3b48;
    border-radius: 50px;
    padding: 40px 20px 72px;
  }
  .mock-status-bar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }
  .mock-header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    position: relative;
  }
  .mock-back {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
  }
  .mock-children {
    overflow: hidden;
    height: 100%;
  }
  .mock-tabbar {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    border-radius: 0 0 30px 30px;
    width: 100%;
  }
`;
