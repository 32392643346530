/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  .btn-back {
    margin-top: 20px;
  }

  .page-title {
    margin-bottom: 10px;
  }

  .page-form {
    padding: 24px 32px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
  }

  .left-side {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .right-side {
    flex-basis: 47%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .practice-image {
    width: 120px;
    height: 160px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .ant-tag {
    color: #fff !important;
    margin-right: 5px;
  }

  .link-image {
    width: 25px;
  }
`;
