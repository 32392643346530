import React, { useState } from "react";
import dayjs from "dayjs";
import { Container } from "./style";
import { Modal, Dropdown, Tooltip, DatePicker, message } from "antd";
import { DownOutlined, ClockCircleOutlined } from "@ant-design/icons";

export default function ScheduleMenu({
  id,
  showSchedule,
  form,
  loading,
  onSubmit,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(null);
  const submitItems = [
    {
      key: "tomorrow",
      label: (
        <div>
          <p className="c-gray">Scheduled time</p>
          <span>Tomorrow at 9 AM</span>
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "custom",
      icon: <ClockCircleOutlined />,
      label: "Custom time",
    },
  ];
  const onMenuClick = (e) => {
    form.validateFields().then(() => {
      if (e.key === "tomorrow") {
        const settedTime = new Date();
        settedTime.setDate(settedTime.getDate() + 1);
        settedTime.setHours(9);
        settedTime.setMinutes(0);
        settedTime.setSeconds(0);
        settedTime.setMilliseconds(0);
        onSubmit({
          values: form.getFieldsValue(),
          status: "SCHEDULED",
          scheduledAt: settedTime,
        });
      }
      if (e.key === "custom") {
        setIsModalOpen(true);
      }
    });
  };

  const handleOk = () => {
    if (scheduleTime) {
      setIsModalOpen(false);
      console.log(scheduleTime, new Date(scheduleTime));
      onSubmit({
        values: form.getFieldsValue(),
        status: "SCHEDULED",
        scheduledAt: new Date(scheduleTime),
      });
    } else {
      message.warning("Please select schedule time!");
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate = (current) => {
    return current && dayjs(current).isBefore(dayjs().startOf("day"));
  };
  const disabledDateTime = (dates) => {
    const hour = dayjs().hour();
    const minute = dayjs().minute();
    // 当日只能选择当前时间之后的时间点
    if (dates && dayjs(dates).date() !== dayjs().date()) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    }
    if (dayjs(dates).hour() !== dayjs().hour()) {
      return {
        disabledHours: () => range(0, hour),
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    }
    return {
      disabledHours: () => range(0, hour),
      disabledMinutes: () => range(0, minute + 1),
      disabledSeconds: () => [],
    };
  };
  const onTimeChange = (date) => {
    setScheduleTime(date);
  };

  return (
    <Container>
      <Dropdown.Button
        icon={<DownOutlined />}
        type="primary"
        placement="topRight"
        size="large"
        trigger="click"
        menu={{
          items: submitItems,
          onClick: onMenuClick,
        }}
        buttonsRender={([leftButton, rightButton]) => [
          React.cloneElement(leftButton, {
            type: "primary",
            htmlType: "submit",
            block: true,
            loading: loading,
          }),
          <Tooltip
            title="Schedule send"
            key="rightButton"
            placement="bottomRight"
          >
            {showSchedule &&
              React.cloneElement(rightButton, {
                type: "primary",
                loading: loading,
              })}
          </Tooltip>,
        ]}
      >
        {!id ? "Publish" : "Publish now"}
      </Dropdown.Button>
      <Modal
        title="Schedule send"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <DatePicker
          size="large"
          format="YYYY-MM-DD HH:mm"
          showTime={{
            defaultValue: dayjs("00:00:00", "HH:mm:ss"),
          }}
          style={{
            width: "100%",
          }}
          minuteStep={5}
          disabledDate={disabledDate}
          disabledTime={disabledDateTime}
          defaultValue={scheduleTime}
          onChange={onTimeChange}
        />
      </Modal>
    </Container>
  );
}
