import React, { useState } from "react";
import request from "../../../api/axios";
import { Container } from "./style";
import { useNavigate } from "react-router-dom";
import { Button, Form, Card, Empty, Spin, List, Avatar } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import SelectTags from "../../../components/SelectTags/index";
const areaOptions = [
  { value: "Awe", label: "Awe", backgroundColor: "#547756" },
  { value: "Compassion", label: "Compassion", backgroundColor: "#547756" },
  {
    value: "Self-compassion",
    label: "Self-compassion",
    backgroundColor: "#547756",
  },
  { value: "Empathy", label: "Empathy", backgroundColor: "#547756" },
  { value: "Forgiveness", label: "Forgiveness", backgroundColor: "#547756" },
  { value: "Gratitude", label: "Gratitude", backgroundColor: "#547756" },
  { value: "Happiness", label: "Happiness", backgroundColor: "#547756" },
  { value: "Kindness", label: "Kindness", backgroundColor: "#547756" },
  { value: "Mindfulness", label: "Mindfulness", backgroundColor: "#547756" },
  { value: "Optimism", label: "Optimism", backgroundColor: "#547756" },
  { value: "Purpose", label: "Purpose", backgroundColor: "#547756" },
  { value: "Resilience", label: "Resilience", backgroundColor: "#547756" },
  { value: "Goals", label: "Goals", backgroundColor: "#547756" },
  { value: "Parenting", label: "Parenting", backgroundColor: "#547756" },
  { value: "WorkLife", label: "WorkLife", backgroundColor: "#547756" },
  { value: "Career", label: "Career", backgroundColor: "#547756" },
  { value: "Relationship", label: "Relationship", backgroundColor: "#547756" },
  { value: "Fitness", label: "Fitness", backgroundColor: "#547756" },
  { value: "Passions", label: "Passions", backgroundColor: "#547756" },
  { value: "Friendship", label: "Friendship", backgroundColor: "#547756" },
  { value: "Wealth", label: "Wealth", backgroundColor: "#547756" },
  { value: "Spirituality", label: "Spirituality", backgroundColor: "#547756" },
  { value: "Sleep", label: "Sleep", backgroundColor: "#547756" },
  { value: "Motivation", label: "Motivation", backgroundColor: "#547756" },
  {
    value: "Self-discipline",
    label: "Self-discipline",
    backgroundColor: "#547756",
  },
  {
    value: "Communication",
    label: "Communication",
    backgroundColor: "#547756",
  },
  { value: "Nutrition", label: "Nutrition", backgroundColor: "#547756" },
  {
    value: "Growth mindset",
    label: "Growth mindset",
    backgroundColor: "#547756",
  },
  {
    value: "Procrastination",
    label: "Procrastination",
    backgroundColor: "#547756",
  },
];
const roleOptions = [
  { value: "Engineer", label: "Engineer", backgroundColor: "#547756" },
  { value: "Founder", label: "Founder", backgroundColor: "#547756" },
  { value: "Designer", label: "Designer", backgroundColor: "#547756" },
  { value: "Manager", label: "Manager", backgroundColor: "#547756" },
  { value: "Teacher", label: "Teacher", backgroundColor: "#547756" },
  {
    value: "Helathcare provider",
    label: "Helathcare provider",
    backgroundColor: "#547756",
  },
  { value: "Coach", label: "Coach", backgroundColor: "#547756" },
  { value: "Student", label: "Student", backgroundColor: "#547756" },
];
const toneOptions = [
  { value: "Personable", label: "Personable", backgroundColor: "#547756" },
  { value: "Confident", label: "Confident", backgroundColor: "#547756" },
  { value: "Empathetic", label: "Empathetic", backgroundColor: "#547756" },
  { value: "Engaging", label: "Engaging", backgroundColor: "#547756" },
  { value: "Witty", label: "Witty", backgroundColor: "#547756" },
  { value: "Direct", label: "Direct", backgroundColor: "#547756" },
  { value: "Positive", label: "Positive", backgroundColor: "#547756" },
];

export default function CreatePrompt() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [areaList, setAreaList] = useState(areaOptions);
  const [roleList, setRoleList] = useState(roleOptions);
  const [toneList, setToneList] = useState(toneOptions);
  const [promptList, setPromptList] = useState([]);

  // Form
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const areas = values.area.map((v) => v.value).join(" or ");
      const roles = values.role.map((v) => v.value).join(" or ");
      const tones = values.tone.map((v) => v.value).join(" or ");
      console.log(values);
      const res = await request({
        url: "ask-gpt",
        data: {
          input: `Please generate 5 journaling prompts based on the guidance below: 1. the journaling prompts will help the reader improve their ${areas}. If the the user chooses two areas to improve, please also consider the connection between those two areas; 2. The journaling prompts will be customized to a reader who is a ${roles}; 3. The journaling prompts will have a ${tones} tone; 4. Each prompt no more than 300 characters. Thanks!`,
        },
      });
      console.log(res);
      setPromptList(
        res.map((v) => {
          return { content: v.substring(2) };
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onClear = () => {
    form.resetFields();
  };

  const onBack = () => {
    navigate("/contentManager");
  };

  const onShare = (item) => {
    const content = item.content.trim();
    navigate(`/CreatePrompt?content=${content}`);
  };

  return (
    <Container forceRender>
      <div className="page-header">
        <Button
          className="btn-back"
          icon={<LeftOutlined />}
          type="text"
          onClick={onBack}
        >
          Content Manager
        </Button>
        <div className="page-title">AI copilot - prompt generation</div>
      </div>
      <div className="page-form">
        <div className="left-side">
          <Card title="Customize guidance" className="full-height">
            <Form
              form={form}
              name="aiForm"
              layout="vertical"
              size="large"
              className="full-width"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="What area will the prompt help them reflect on and improve (Select max 2 items)"
                name="area"
                rules={[{ required: true, message: "Please select area" }]}
              >
                <SelectTags
                  options={areaList}
                  maxCount={2}
                  placeholder="Please select"
                  selectWidth={150}
                  outline
                  addOther
                />
              </Form.Item>
              <Form.Item
                label="What is the audience's background (Select max 2 items)"
                name="role"
                rules={[{ required: true, message: "Please select role" }]}
              >
                <SelectTags
                  options={roleList}
                  maxCount={2}
                  placeholder="Please select"
                  selectWidth={150}
                  outline
                  addOther
                />
              </Form.Item>
              <Form.Item
                label="Please set a tone for the content (Select max 2 items)"
                name="tone"
                rules={[{ required: true, message: "Please select tone" }]}
              >
                <SelectTags
                  options={toneList}
                  maxCount={2}
                  placeholder="Please select"
                  selectWidth={150}
                  outline
                  addOther
                />
              </Form.Item>
              <br />
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
                <Button className="ml-10" loading={loading} onClick={onClear}>
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <div className="right-side">
          <Spin spinning={loading}>
            <Card
              title="Generated journaling prompts"
              bodyStyle={{ minHeight: "calc(100vh - 267px)" }}
            >
              {promptList && promptList.length < 1 && (
                <Empty className="empty-box" description="Coming soon." />
              )}
              {promptList.length > 0 && (
                <List
                  itemLayout="horizontal"
                  dataSource={promptList}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={() => onShare(item)}>
                          Share
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={`https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1200px-ChatGPT_logo.svg.png`}
                          />
                        }
                        title={`Prompt ${index + 1}`}
                        description={item.content}
                      />
                    </List.Item>
                  )}
                />
              )}
            </Card>
          </Spin>
        </div>
      </div>
    </Container>
  );
}
