/* eslint-disable react/prop-types */
import React from "react";
import Components from "../../components";
import { Container } from "./style";
import { useNavigate } from "react-router-dom";

export default function Page404() {
  const { Button } = Components;
  const navigate = useNavigate();
  return (
    <Container>
      <div className="box404">
        <div className="dot404"></div>
        <div className="big-alert">404</div>
        <div className="small-alert">Page not found</div>
        <Button
          className="btn404"
          title="Go home"
          onClick={() => {
            navigate("/ProfileBuilder");
          }}
        />
      </div>
    </Container>
  );
}
