import { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Tag, Tooltip, Select, Divider, Input, Space, Button } from "antd";
import { Container } from "./style";

export default function SelectTags({
  options,
  initialValues,
  maxCount = 3,
  placeholder = "New tag",
  selectWidth = 105,
  outline = false,
  addOther = false,
  onChange,
}) {
  const [tags, setTags] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectVisible, setSelectVisible] = useState(false);
  const selectRef = useRef(null);
  useEffect(() => {
    if (initialValues && initialValues.length > 0) {
      const selected = options.filter((v) => initialValues.includes(v.value));
      setTags(selected);
      onChange(selected);
    } else {
      setTags([]);
    }
    const _options = options.filter((v) => {
      const tagValues = tags.map((v) => v.value);
      return !tagValues.includes(v.value);
    });
    setFilteredOptions(_options);
  }, [initialValues, options]);
  useEffect(() => {
    if (selectVisible) {
      selectRef.current?.focus();
    }
  }, [selectVisible]);
  const handleClose = (removedTag, e) => {
    e.preventDefault();
    const newTags = tags.filter((tag) => tag.value !== removedTag.value);
    setTags(newTags);
    onChange(newTags);
  };
  const showSelect = () => {
    setSelectVisible(true);
  };
  const onDropdownVisibleChange = (open) => {
    if (!open) {
      setSelectVisible(false);
    }
  };

  const onSelectChange = (value, option) => {
    const tagValues = tags.map((v) => v.value);
    if (!tagValues.includes(value)) {
      const array = [...tags, option];
      setTags(array);
      onChange(array);
    }
    setSelectVisible(false);
  };

  const inputRef = useRef(null);
  const [inputName, setInputName] = useState([]);
  const onNameChange = (event) => {
    setInputName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    if (inputName.length < 1) {
      return;
    }
    setFilteredOptions([
      { label: inputName, value: inputName },
      ...filteredOptions,
    ]);
    setInputName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Container>
      {tags.map((tag, index) => {
        const isLongTag = tag.label.length > 20;
        const tagElem = (
          <Tag
            className="edit-tag mb-10"
            color={outline ? "green" : tag.backgroundColor}
            key={index}
            closable
            onClose={(e) => handleClose(tag, e)}
          >
            <span>
              {isLongTag ? `${tag.label.slice(0, 20)}...` : tag.label}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag.label} key={index}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {selectVisible && tags.length < maxCount && (
        <Select
          ref={selectRef}
          className="tag-select"
          style={{ width: selectWidth + "px" }}
          showSearch
          defaultOpen
          placeholder="Select"
          optionFilterProp="children"
          onSelect={onSelectChange}
          onDropdownVisibleChange={(open) => onDropdownVisibleChange(open)}
          filterOption={(select, option) =>
            (option?.label ?? "").toLowerCase().includes(select.toLowerCase())
          }
          options={filteredOptions}
          dropdownRender={(menu) => (
            <>
              {menu}
              {addOther && (
                <div>
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space>
                    <Input
                      size="mini"
                      placeholder="Other"
                      ref={inputRef}
                      value={inputName}
                      onChange={onNameChange}
                    />
                    <Button
                      disabled={inputName.length < 1}
                      size="mini"
                      icon={<PlusOutlined />}
                      onClick={addItem}
                    ></Button>
                  </Space>
                </div>
              )}
            </>
          )}
        />
      )}
      {!selectVisible && tags.length < maxCount && (
        <Tag className="site-tag-plus" onClick={showSelect}>
          <PlusOutlined /> {placeholder}
        </Tag>
      )}
    </Container>
  );
}
