/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  .page-header {
    padding: 0 32px;
  }

  .btn-back {
    margin-top: 20px;
  }

  .page-title {
    margin-bottom: 10px;
  }

  .page-form {
    padding: 24px 32px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .left-side {
    flex-basis: 49%;
    height: calc(100vh - 210px);
  }

  .right-side {
    flex-basis: 49%;
    height: calc(100vh - 210px);
    overflow: auto;
  }

  .empty-box {
    margin-top: 100px;
  }
`;
