export const mainTheme = {
  body: "rgba(241, 239, 233, 0.6)",
  primaryDark: "#3D3B48",
  primary70: "rgba(61, 59, 72, 0.7)",
  primaryDefault: "#547756",
  white: "#ffffff",
  characterPrimaryInverse: "#ffffff",
  primaryHover: "#769278",
  primaryPressed: "#4D6552",
  primaryLight: "#EAEFEB",
  disable: "rgba(61, 59, 72, 0.5)",
  black15: "rgba(0, 0, 0, 0.15)",
  none: "none",
  transparent: "transparent",
  backgroundTextFieldDefault: "#FDFDFC",
  error1: "#E05051",
  focusShadow: "rgba(84, 119, 86, 0.4)",
  textFieldDisabled: "#ECEBED",
  tempBackground: "rgba(221, 221, 221, 0.25)",
  tempHoveredBackground: "rgba(221, 221, 221, 0.50)",
  tempSelectedBackground: "rgba(221, 221, 221, 0.90)",
  dustRed5: "#FF4D4F",
  boxShadow05: "rgba(0, 0, 0, 0.05)",
  boxShadow08: "rgba(0, 0, 0, 0.08)",
  boxShadow10: "rgba(0, 0, 0, 0.1)",
  boxShadow12: "rgba(0, 0, 0, 0.12)",
  black05: "rgba(0, 0, 0, 0.05)",
  background1: "#F1EFE9",
  light4: "#DCE1C6",
  default4: "#8A9C40",
  black: "#000000",
  cGray: "#999",
  Alert: {
    textColor: "#3D3B48",
    btn: {
      textColor: "#1890FF",
      background: "#E6F7FF",
      borderColor: "#91D5FF",
    },
    background: {
      Success: "#F5FAF2",
      Error: "#FDF1F1",
      Info: "#E6F7FF",
      Warning: "#FFFBE6",
    },
    borderColor: {
      Success: "#54AD27",
      Error: "#E05051",
      Info: "#91D5FF",
      Warning: "#FFE58F",
    },
    exitIconColor: "rgba(0, 0, 0, 0.45)",
  },
};
export default mainTheme;
