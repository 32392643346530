import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Form,
  Input,
  Modal,
  Avatar,
  message,
  Tag,
  Alert,
  Radio,
  Space,
  Spin,
} from "antd";
import {
  LeftOutlined,
  TagOutlined,
  EditOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import ReactRouterPrompt from "react-router-prompt";
import MockPhone from "../../../components/MockPhone/index";
import ScheduleMenu from "../../../components/ScheduleMenu/index";
import UserAvatar from "../../../assets/User.png";
import LinkIcon from "../../../assets/link.png";
import logo_practice1 from "../../../assets/logo_practice1.png";
import logo_practice2 from "../../../assets/logo_practice2.png";
import { gql, useMutation } from "@apollo/client";
import { setQuoteData } from "../../../store/features/CreateQuote";
const { TextArea } = Input;

const CREATE_QUOTE = gql`
  mutation createQuote($createQuoteInput: CreateQuoteInput!) {
    createQuote(payload: $createQuoteInput) {
      _id
    }
  }
`;
const UPDATE_QUOTE = gql`
  mutation updateQuote($updateQuoteInput: UpdateQuoteInput!) {
    updateQuote(payload: $updateQuoteInput) {
      _id
    }
  }
`;

export default function CreateQuote() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [createQuote] = useMutation(CREATE_QUOTE);
  const [updateQuote] = useMutation(UPDATE_QUOTE);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const location = useLocation();
  const rowData = useSelector((state) => state.quote.quoteData);
  const quoteId = rowData?._id;
  useEffect(() => {
    if (location.pathname === "/CreateQuote" && rowData._id) {
      dispatch(setQuoteData({}));
      rowData = {};
    }
    if (location.pathname === "/EditQuote" && !rowData._id) {
      navigate("/CreateQuote");
    }
  }, [location]);
  // Update quote
  useEffect(() => {
    if (form && rowData._id) {
      form.setFieldsValue(rowData);
    }
  }, [rowData]);

  // Form
  const [form] = Form.useForm();
  const contentValue = Form.useWatch("content", form);
  const visibleValue = Form.useWatch("visible", form);
  const linkValue = Form.useWatch("link", form);

  useEffect(() => {
    console.log("rowData", rowData);
    if (
      (contentValue && contentValue !== rowData.content) ||
      (visibleValue && visibleValue !== rowData.visibleValue) ||
      (linkValue && linkValue !== rowData.link)
    ) {
      setIsChanged(true);
    }
  }, [contentValue, visibleValue, linkValue]);

  useEffect(() => {
    if (loading) {
      setIsChanged(false);
    }
  }, [loading]);

  const onSubmit = async ({ values, status, scheduledAt }) => {
    setLoading(true);
    try {
      if (!quoteId) {
        const param = {
          status,
          coach: userInfo.coach?._id,
          publishedAt: new Date(),
          ...values,
        };
        if (scheduledAt) {
          param.scheduledAt = scheduledAt;
        }
        await createQuote({
          variables: {
            createQuoteInput: param,
          },
        });
        message.success("Insight has been created!");
      } else {
        const param = {
          _id: quoteId,
          status,
          coach: userInfo.coach?._id,
          content: values.content,
          visibility: values.visibility,
          link: values.link,
        };
        if (scheduledAt) {
          param.scheduledAt = scheduledAt;
        }
        await updateQuote({
          variables: {
            updateQuoteInput: param,
          },
        });
        message.success("Insight has been updated!");
      }
      dispatch(setQuoteData({}));
      navigate("/contentManager?tabName=2");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    onSubmit({ values, status: "PUBLISHED" });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onDraft = () => {
    const values = form.getFieldsValue();
    onSubmit({ values, status: "DRAFTED" });
  };

  const onBack = () => {
    navigate("/contentManager");
  };

  return (
    <Container forceRender>
      <ReactRouterPrompt when={isChanged}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <Modal
              title="Unsaved changes"
              open
              footer={[
                <div key="flex-between" className="flex-between">
                  <Button
                    key="discard"
                    danger
                    loading={loading}
                    onClick={onConfirm}
                  >
                    Discard Changes
                  </Button>
                  <div key="div">
                    <Button key="back" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      key="save"
                      type="primary"
                      loading={loading}
                      onClick={onDraft}
                    >
                      Save Draft
                    </Button>
                  </div>
                </div>,
              ]}
            >
              <p>
                You are about to leave this page with unsaved changes. Would you
                like to save these changes before leaving?
              </p>
            </Modal>
          )
        }
      </ReactRouterPrompt>
      <div className="page-form">
        <div className="left-side">
          <Button
            className="btn-back"
            icon={<LeftOutlined />}
            type="text"
            onClick={onBack}
          >
            Content Manager
          </Button>

          <div className="page-title">
            {!quoteId ? "Create a new Insight" : "Update a Insight"}
            {/* <Spin
              className="ml-10"
              spinning={quoteId && listQuotesLoading}
            ></Spin> */}
          </div>
          <Form
            form={form}
            name="quoteForm"
            layout="vertical"
            size="large"
            className="full-width"
            initialValues={{
              visibility: "PRIVATE",
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Enter your insight"
              name="content"
              rules={[{ required: true, message: "Please input insight" }]}
            >
              <TextArea
                showCount
                maxLength={500}
                placeholder="Empty"
                style={{ resize: "none", height: 250 }}
              />
            </Form.Item>
            <Form.Item
              label="Visible to"
              name="visibility"
              rules={[{ required: true, message: "Please select visibility" }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"PRIVATE"}>Subscribers Only</Radio>
                  <Radio value={"PUBLIC"}>All</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Link"
              name="link"
              rules={[
                {
                  pattern: /^(https?:\/\/)?([\w-]+\.+[\w-]+)+([/?].*)?$/,
                  message: "Please enter a valid link with a valid domain",
                },
              ]}
            >
              <Input placeholder="Please add link" />
            </Form.Item>
            {rowData.status === "SCHEDULED" && rowData.scheduledAt && (
              <div>
                <Alert
                  className="full-width clock-tag"
                  message={`This insight is scheduled to be published at ${new Date(
                    rowData.scheduledAt
                  ).toLocaleString()}.`}
                  icon={<ClockCircleOutlined />}
                  type="success"
                  showIcon
                />
              </div>
            )}
            <Form.Item>
              <ScheduleMenu
                id={quoteId}
                showSchedule={!quoteId || rowData.status !== "PUBLISHED"}
                form={form}
                loading={loading}
                onSubmit={onSubmit}
              ></ScheduleMenu>
              <Button
                block
                className="mt-16"
                loading={loading}
                onClick={onDraft}
              >
                Save as draft
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="right-side">
          <MockPhone showTabbar>
            <div className="info-box gray-all">
              <div className="flex-between mb-10">
                <div className="c-gray">By {userInfo?.coach?.name}</div>
                <TagOutlined />
              </div>
              <div className="info-content">
                "Is there a simple way to display a color bitmap in grayscale
                with just HTML/CSS?"
              </div>
              <div className="text-right">
                <Button type="primary" icon={<EditOutlined />}>
                  Write
                </Button>
              </div>
            </div>
            <div className="flex-between mb-10">
              <div>Today's Insights</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
            <div className="info-box">
              <div className="flex-between mb-10">
                <div>
                  <Avatar
                    src={userInfo?.coach?.profile_image || UserAvatar}
                    className="mr-10"
                  />
                  {userInfo?.coach?.name}
                  <span className="c-gray ml-10">
                    {new Date().toISOString().slice(0, 10)}
                  </span>
                </div>
                <TagOutlined />
              </div>
              <div className="info-content">{contentValue || "Empty"}</div>
              {!!linkValue && (
                <div className="align-items-center">
                  <img
                    className="link-image mr-10"
                    src={LinkIcon}
                    alt="image"
                  />
                  <span className="c-primary fs-14 bold">{linkValue}</span>
                </div>
              )}
            </div>
            <div className="flex-between mb-10 gray-all">
              <div>Practices Recommended for you</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
            <div className="flex-between mb-10 gray-all">
              <img
                className="practice-image"
                src={logo_practice2}
                alt="image"
              />
              <div className="fs-13">
                <div className="bold mb-10">
                  <Tag color="#D37773">Gratitude</Tag>
                  <i>Premium</i>
                </div>
                <h4 className="mb-10">Gratitude Journal</h4>
                <div>Reflect on what you are grateful for every day</div>
                <div className="flex-between mt-10">
                  <div className="c-gray fs-12">6 min</div>
                  <div className="c-gray fs-12">323 students</div>
                </div>
              </div>
            </div>
            <div className="flex-between gray-all">
              <img
                className="practice-image"
                src={logo_practice1}
                alt="image"
              />
              <div className="fs-13">
                <div className="bold mb-10">
                  <Tag color="#A38BB6">Grit</Tag>
                  <i>Premium</i>
                </div>
                <h4 className="mb-10">Success Story</h4>
                <div>
                  We learn from experience by incorporating our experiences...
                </div>
                <div className="flex-between mt-10">
                  <div className="c-gray fs-12">6 min</div>
                  <div className="c-gray fs-12">423 students</div>
                </div>
              </div>
            </div>
          </MockPhone>
        </div>
      </div>
    </Container>
  );
}
