import { createSlice } from "@reduxjs/toolkit";

const localPrompt = localStorage.getItem("uvita-prompt");
export const promptSlice = createSlice({
  name: "prompt",
  initialState: {
    promptData: (localPrompt && JSON.parse(localPrompt)) || {},
  },
  reducers: {
    setPromptData: (state, action) => {
      state.promptData = action.payload;
      localStorage.setItem(
        "uvita-prompt",
        JSON.stringify(action.payload) || ""
      );
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { setPromptData } = promptSlice.actions;

export default promptSlice.reducer;
