import { useEffect, useState } from "react";
import { Button, Upload } from "antd";
import { gql, useLazyQuery } from "@apollo/client";

const GENERATE_SIGNED_URL = gql`
  query GenerateSignedUrl($payload: UploadUserImageInput!) {
    generateSingedURL(payload: $payload) {
      signedURL
    }
  }
`;

export default function PutUpload({ fileName = "image.png", onChange }) {
  const [uploadAction, setUploadAction] = useState("");
  const [generateSignedUrl, { urlData }] = useLazyQuery(GENERATE_SIGNED_URL);

  useEffect(() => {
    generateSignedUrl({
      variables: {
        payload: {
          contentType: "png",
          fileName,
        },
      },
    }).then((v) => {
      setUploadAction(v.data.generateSingedURL.signedURL);
    });
  }, [urlData, generateSignedUrl]);
  const handleUploadChange = (info) => {
    if (info.file.status === "done") {
      onChange(uploadAction.split("?Content-Type=")[0]);
    }
  };
  const onUpload = ({ action, file, onError, onProgress, onSuccess }) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", action, true);
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percent = Math.floor((event.loaded / event.total) * 100);
        onProgress({ percent });
      }
    };
    xhr.onerror = (event) => {
      onError(event);
    };
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          onSuccess(xhr.response, xhr);
        } else {
          onError(new Error(xhr.statusText), xhr);
        }
      }
    };
    xhr.send(file);
  };

  return (
    <Upload
      name="logo"
      action={uploadAction}
      listType="picture"
      onChange={handleUploadChange}
      customRequest={onUpload}
    >
      <Button>Upload</Button>
    </Upload>
  );
}
