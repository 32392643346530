import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Modal, Tabs, Radio, Space, Tag } from "antd";
import { Container } from "./style";
import PracticeManager from "./PracticeManager";
import QuoteManager from "./QuoteManager";
import PromptManager from "./PromptManager";
import { setPracticeData } from "../../../store/features/CreatePractice";
import { setQuoteData } from "../../../store/features/CreateQuote";
import { setPromptData } from "../../../store/features/CreatePrompt";
import { gql, useLazyQuery } from "@apollo/client";

const GET_COUNT = gql`
  query GetCountsOfMyContentAndSubscriptions {
    getCountsOfMyContentAndSubscriptions {
      practices
      quotes
      prompts
      subscriptions
    }
  }
`;

export default function ContentManager() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [radioValue, setRadioValue] = useState("prompt");
  const [params] = useSearchParams();

  const [getCount, { data: countRes, refetch }] = useLazyQuery(GET_COUNT);
  const [subCount, setSubCount] = useState(0);
  const [practiceCount, setPracticeCount] = useState(0);
  const [quoteCount, setQuoteCount] = useState(0);
  const [promptCount, setPromptCount] = useState(0);
  useEffect(() => {
    refetch();
    dispatch(setPracticeData({}));
    dispatch(setQuoteData({}));
    dispatch(setPromptData({}));
  }, []);
  useEffect(() => {
    setSubCount(countRes?.getCountsOfMyContentAndSubscriptions?.subscriptions);
    setPracticeCount(countRes?.getCountsOfMyContentAndSubscriptions?.practices);
    setQuoteCount(countRes?.getCountsOfMyContentAndSubscriptions?.quotes);
    setPromptCount(countRes?.getCountsOfMyContentAndSubscriptions?.prompts);
  }, [countRes]);

  const tabName =
    (params && params.getAll("tabName") && params.getAll("tabName")[0]) || "3";
  const tabs = [
    {
      key: "3",
      label: (
        <span>
          Reflections
          <Tag className="ml-10">{promptCount}</Tag>
        </span>
      ),
      children: <PromptManager />,
    },
    {
      key: "2",
      label: (
        <span>
          Insights
          <Tag className="ml-10">{quoteCount}</Tag>
        </span>
      ),
      children: <QuoteManager />,
    },
    {
      key: "1",
      label: (
        <span>
          Practices
          <Tag className="ml-10">{practiceCount}</Tag>
        </span>
      ),
      children: <PracticeManager />,
    },
  ];

  useEffect(() => {
    getCount();
  }, []);

  // Actions
  const onAdd = () => {
    setIsModalOpen(true);
  };

  const onAiPrompt = () => {
    navigate("/AiPrompt");
  };

  const onOk = () => {
    switch (radioValue) {
      case "practice":
        navigate("/CreatePractice");
        break;
      case "quote":
        navigate("/CreateQuote");
        break;
      case "prompt":
        navigate("/CreatePrompt");
        break;
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };
  return (
    <Container>
      <Modal
        title="Select a content type"
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Radio.Group onChange={onRadioChange} value={radioValue}>
          <Space direction="vertical">
            <Radio value="prompt">Reflection</Radio>
            <Radio value="quote">Insight</Radio>
            <Radio value="practice">Practice</Radio>
          </Space>
        </Radio.Group>
      </Modal>
      <div className="flex-between">
        <div className="page-title">
          Content Manager
          <span className="page-subtitle">
            Subscribers:
            <Tag className="ml-10">{subCount}</Tag>
          </span>
        </div>
        <div>
          <Button
            ghost
            size="large"
            type="primary"
            className="mr-10"
            onClick={onAiPrompt}
          >
            AI Copilot
          </Button>
          <Button size="large" type="primary" onClick={onAdd}>
            Create new content
          </Button>
        </div>
      </div>
      <Tabs defaultActiveKey={tabName} items={tabs} className="content-tabs" />
    </Container>
  );
}
