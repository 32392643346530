import { createSlice } from "@reduxjs/toolkit";

const localQuote = localStorage.getItem("uvita-quote");
export const quoteSlice = createSlice({
  name: "quote",
  initialState: {
    quoteData: (localQuote && JSON.parse(localQuote)) || {},
  },
  reducers: {
    setQuoteData: (state, action) => {
      state.quoteData = action.payload;
      localStorage.setItem("uvita-quote", JSON.stringify(action.payload) || "");
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { setQuoteData } = quoteSlice.actions;

export default quoteSlice.reducer;
