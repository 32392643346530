/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 32px;

  .content-tabs {
    background: #fff;
    margin-top: 20px;
  }
  .content-tabs .ant-tabs-tab {
    padding: 12px 20px;
  }

  .table-filter {
    margin: 10px 20px 10px 0;
  }
  .table-search {
    max-width: 50%;
  }
`;
