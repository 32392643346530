import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Form,
  Input,
  Modal,
  Tabs,
  Avatar,
  message,
  Alert,
  Radio,
  Space,
} from "antd";
import {
  LeftOutlined,
  TagOutlined,
  EditOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import ReactRouterPrompt from "react-router-prompt";
import MockPhone from "../../../components/MockPhone/index";
import ScheduleMenu from "../../../components/ScheduleMenu/index";
import UserAvatar from "../../../assets/User.png";
import { gql, useMutation } from "@apollo/client";
import { setPromptData } from "../../../store/features/CreatePrompt";
const { TextArea } = Input;

const CREATE_PROMPT = gql`
  mutation createPrompt($createPromptInput: CreatePromptInput!) {
    createPrompt(payload: $createPromptInput) {
      _id
    }
  }
`;
const UPDATE_PROMPT = gql`
  mutation updatePrompt($updatePromptInput: UpdatePromptInput!) {
    updatePrompt(payload: $updatePromptInput) {
      _id
    }
  }
`;

export default function CreatePrompt() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [createPrompt] = useMutation(CREATE_PROMPT);
  const [updatePrompt] = useMutation(UPDATE_PROMPT);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const [params] = useSearchParams();
  const location = useLocation();
  let rowData = useSelector((state) => state.prompt.promptData);
  const promptId = rowData?._id;
  useEffect(() => {
    if (location.pathname === "/CreatePrompt" && rowData._id) {
      dispatch(setPromptData({}));
      rowData = {};
    }
    if (location.pathname === "/EditPrompt" && !rowData._id) {
      navigate("/CreatePrompt");
    }
  }, [location]);
  // Update prompt
  useEffect(() => {
    if (form && rowData._id) {
      form.setFieldsValue(rowData);
    }
  }, [rowData]);

  // Get content from AI tool and fill to the form
  const promptContent =
    params && params.getAll("content") && params.getAll("content")[0];
  useEffect(() => {
    if (promptContent) {
      form.setFieldsValue({ content: promptContent });
    }
  }, [promptContent]);

  // Form
  const [form] = Form.useForm();
  const contentValue = Form.useWatch("content", form);
  const visibleValue = Form.useWatch("visible", form);

  useEffect(() => {
    if (
      (contentValue && contentValue !== rowData.content) ||
      (visibleValue && visibleValue !== rowData.visibleValue)
    ) {
      setIsChanged(true);
    }
  }, [contentValue, visibleValue]);
  useEffect(() => {
    if (loading) {
      setIsChanged(false);
    }
  }, [loading]);

  const onSubmit = async ({ values, status, scheduledAt }) => {
    setLoading(true);
    try {
      if (!promptId) {
        const param = {
          status,
          authorName: userInfo.user?.name,
          coach: userInfo.coach?._id,
          createdBy: userInfo.coach?._id,
          date: new Date(),
          ...values,
        };
        if (scheduledAt) {
          param.scheduledAt = scheduledAt;
        }
        await createPrompt({
          variables: {
            createPromptInput: param,
          },
        });
        message.success("Reflection has been created!");
      } else {
        const param = {
          _id: promptId,
          status,
          createdBy: userInfo.coach?._id,
          content: values.content,
          visibility: values.visibility,
        };
        if (scheduledAt) {
          param.scheduledAt = scheduledAt;
        }
        await updatePrompt({
          variables: {
            updatePromptInput: param,
          },
        });
        message.success("Reflection has been updated!");
      }
      dispatch(setPromptData({}));
      navigate("/contentManager?tabName=3");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    onSubmit({ values, status: "PUBLISHED" });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const onDraft = () => {
    const values = form.getFieldsValue();
    onSubmit({ values, status: "DRAFTED" });
  };

  const onBack = () => {
    navigate("/contentManager");
  };

  const onBackAI = () => {
    navigate("/aiPrompt");
  };

  return (
    <Container forceRender>
      <ReactRouterPrompt when={isChanged}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <Modal
              title="Unsaved changes"
              open
              footer={[
                <div key="flex-between" className="flex-between">
                  <Button
                    key="discard"
                    danger
                    loading={loading}
                    onClick={onConfirm}
                  >
                    Discard Changes
                  </Button>
                  <div key="div">
                    <Button key="back" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      key="save"
                      type="primary"
                      loading={loading}
                      onClick={onDraft}
                    >
                      Save Draft
                    </Button>
                  </div>
                </div>,
              ]}
            >
              <p>
                You are about to leave this page with unsaved changes. Would you
                like to save these changes before leaving?
              </p>
            </Modal>
          )
        }
      </ReactRouterPrompt>
      <div className="page-form">
        <div className="left-side">
          {!promptContent && (
            <Button
              className="btn-back"
              icon={<LeftOutlined />}
              type="text"
              onClick={onBack}
            >
              Content Manager
            </Button>
          )}
          {promptContent && (
            <Button
              className="btn-back"
              icon={<LeftOutlined />}
              type="text"
              onClick={onBackAI}
            >
              AI Copilot
            </Button>
          )}

          <div className="page-title">
            {!promptId ? "Create a new Reflection" : "Update a Reflection"}
          </div>
          <Form
            form={form}
            name="promptForm"
            layout="vertical"
            size="large"
            className="full-width"
            initialValues={{
              visibility: "PRIVATE",
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Enter your reflection"
              name="content"
              rules={[{ required: true, message: "Please input reflection" }]}
            >
              <TextArea
                showCount
                maxLength={300}
                placeholder="Empty"
                style={{ resize: "none", height: 200 }}
              />
            </Form.Item>
            <Form.Item
              label="Visible to"
              name="visibility"
              rules={[{ required: true, message: "Please select visibility" }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"PRIVATE"}>Subscribers Only</Radio>
                  <Radio value={"PUBLIC"}>All</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <br />
            {rowData.status === "SCHEDULED" && rowData.scheduledAt && (
              <div>
                <Alert
                  className="full-width clock-tag"
                  message={`This reflection is scheduled to be published at ${new Date(
                    rowData.scheduledAt
                  ).toLocaleString()}.`}
                  icon={<ClockCircleOutlined />}
                  type="success"
                  showIcon
                />
              </div>
            )}
            <Form.Item>
              <ScheduleMenu
                id={promptId}
                showSchedule={!promptId || rowData.status !== "PUBLISHED"}
                form={form}
                loading={loading}
                onSubmit={onSubmit}
              ></ScheduleMenu>
              <Button
                block
                className="mt-16"
                loading={loading}
                onClick={onDraft}
              >
                Save as draft
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="right-side">
          <MockPhone showTabbar>
            <div className="prompt-top gray-all">
              <h3>Hello {userInfo?.coach?.name}</h3>
              <Tabs
                defaultActiveKey="1"
                items={[
                  { key: "1", label: "For you" },
                  { key: "2", label: "Featured" },
                ]}
              ></Tabs>
            </div>
            <div className="flex-between mb-10">
              <div>Reflections</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
            <div className="info-box">
              <div className="flex-between mb-10">
                <div className="c-gray">By {userInfo?.coach?.name}</div>
                <TagOutlined />
              </div>
              <div className="info-content">{contentValue || "Empty"}</div>
              <div className="text-right">
                <Button type="primary" icon={<EditOutlined />}>
                  Write
                </Button>
              </div>
            </div>
            <div className="flex-between mb-10 gray-all">
              <div>Today's Insights</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
            <div className="info-box gray-all">
              <div className="flex-between mb-10">
                <div>
                  <Avatar src={UserAvatar} className="mr-10" />
                  Character Lab
                  <span className="c-gray ml-10">FEB 24, 2023</span>
                </div>
                <TagOutlined />
              </div>
              <div className="info-content">
                Why does gratitude matter? When you feel gratitude, you feel a
                sense of abundance. When you express gratitude—especially when
                it’s heartfelt—you strengthen your relationships with others.
                Grateful people are happier and more fulfilled. And gratitude
                leads you to be nicer to other people: more cooperative,
                patient, and trusting.
              </div>
            </div>
            <div className="flex-between mb-10 gray-all">
              <div>Practices Recommended for you</div>
              <Button type="text">
                <b>More</b>
              </Button>
            </div>
          </MockPhone>
        </div>
      </div>
    </Container>
  );
}
