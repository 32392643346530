/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  .page-top {
    padding: 12px 32px;
    border-bottom: 1px solid ${({ theme }) => theme.black15};
    display: flex;
    justify-content: space-between;
    gap: 42px;
  }

  .btn-back {
    margin: 10px 16px 0;
  }

  .page-form {
    padding: 24px 32px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
  }

  .left-side {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-line {
    background: ${({ theme }) => theme.black05};
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin: 24px 0;
  }

  .right-side {
    flex-basis: 47%;
    display: flex;
    justify-content: center;
    margin-top: -20px;
  }

  // Summary
  .author-line {
    border-top: 1px solid ${({ theme }) => theme.black15};
    border-bottom: 1px solid ${({ theme }) => theme.black15};
    padding: 12px 0;
    margin-top: 16px;
  }

  .author-name {
    font-weight: bold;
    font-size: 14px;
  }

  .author-info {
    flex: 1;
  }

  .author-desc {
    font-size: 12px;
    color: ${({ theme }) => theme.cGray};
  }

  .full-tabs .ant-tabs-nav {
    width: 100% !important;
  }

  .full-tabs .ant-tabs-tab {
    display: block; // centers text inside tabs
    flex: 1;
    text-align: center;
  }

  .full-tabs .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

  .cover-image {
    margin: 12px 0 24px;
  }

  // Edit
  .time-hint {
    text-align: center;
    padding: 70px 0;
    color: ${({ theme }) => theme.cGray};
  }
  .edit-title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 70px;
  }
  .edit-body {
    height: 310px;
    overflow: auto;
  }
  .mock-progress {
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  // Edit add
  .edit-add-title {
    padding: 30px 0;
    font-size: 30px;
  }
  .edit-add-subtitle {
    font-weight: bold;
    margin: 12px 0;
  }
  .edit-add-textarea {
    margin-top: 16px;
  }
  .edit-add-btns {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 24px);
  }
  .mock-options {
    margin-top: 30px;
  }

  // Wrap
  .complete-hint {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0 150px;
  }
  .wrap-body {
    max-height: 200px;
    overflow: auto;
    margin-bottom: 10px;
  }
  .mock-congrats {
    display: block;
    margin: 50px auto 30px;
  }
`;
