import React, { useState } from "react";
import { Container } from "./style";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Button, Form, Input, Result, message } from "antd";
import { gql, useMutation } from "@apollo/client";

const CONFIRM_PASSWORD = gql`
  mutation confirmPassword($input: ConfirmPasswordInput!) {
    confirmPassword(confirmPasswordInput: $input) {
      message
    }
  }
`;

export default function ConfirmForm() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const code = params.getAll("confirmation_code")[0];
  const user = params.getAll("user_name")[0];
  const [isDone, setIsDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const [confirmPassword] = useMutation(CONFIRM_PASSWORD);

  const onSubmit = async (values) => {
    console.log("Success:", values);
    setLoading(true);
    try {
      await confirmPassword({
        variables: {
          input: {
            cognitoUserId: user,
            email: values.email,
            newPassword: values.newPassword,
            verificationCode: code,
          },
        },
      });
      message.success("Password has been updated");
      setIsDone(true);
      setTimeout(() => {
        navigate("/Login");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };
  const goLogin = () => {
    navigate("/Login");
  };
  return (
    <Container>
      <div className="header">
        <div className="header-logo">
          <span className="main">UVITA</span>
          <span className="sub">PRO</span>
        </div>
        <div></div>
      </div>
      <div className="form">
        <div className="form-box">
          <div>
            {!isDone ? (
              <div>
                <div className="form-title">Confirm Password</div>
                <Form
                  name="confirmForm"
                  layout="vertical"
                  size="large"
                  onFinish={onSubmit}
                  onFinishFailed={onSubmitFailed}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="Input your email" />
                  </Form.Item>
                  <Form.Item
                    label="New password"
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Input your password" />
                  </Form.Item>
                  <Form.Item
                    label="Re-enter your new password"
                    name="confirmNewPassword"
                    dependencies={["newPassword"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Input your password" />
                  </Form.Item>
                  <br />
                  <Form.Item wrapperCol={{ span: 12 }}>
                    <Button
                      type="primary"
                      block
                      loading={loading}
                      htmlType="submit"
                    >
                      Change Password
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <div>
                <Result
                  status="success"
                  title="New password is saved successfully!"
                  subTitle="You'll be automatically directed to the login page. Please click the following link if you are not directed to login page."
                  extra={[
                    <Button
                      type="primary"
                      size="large"
                      key="goLogin"
                      onClick={goLogin}
                    >
                      Go to login page
                    </Button>,
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
