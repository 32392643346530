import React, { useState } from "react";
import { Button, Form, Input, Result, message } from "antd";
import { gql, useMutation } from "@apollo/client";

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $input) {
      message
    }
  }
`;

export default function ForgetForm() {
  const [isDone, setIsDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const onSubmit = async (values) => {
    console.log("Success:", values);
    try {
      setLoading(true);
      await forgotPassword({
        variables: {
          input: values,
        },
      });
      message.success("Email has been sent, please check your email.");
      setIsDone(true);
    } finally {
      setLoading(false);
    }
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };
  return (
    <div>
      {!isDone ? (
        <div>
          <div className="form-title">Forgot Password</div>
          <Form
            name="forgetForm"
            layout="vertical"
            size="large"
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Input your email" />
            </Form.Item>
            <br />
            <Form.Item wrapperCol={{ span: 12 }}>
              <Button type="primary" block loading={loading} htmlType="submit">
                Email me a recovery link
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <Result
          status="success"
          title="Recover Password"
          subTitle="An email has been sent. Please click the link when you get it."
        />
      )}
    </div>
  );
}
