import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Form, Input, Alert, Result, message } from "antd";
import { gql, useMutation } from "@apollo/client";

const CHANGE_PASSWORD = gql`
  mutation changePassword($changePasswordInput: ChangePasswordInput!) {
    changePassword(changePasswordInput: $changePasswordInput) {
      message
    }
  }
`;

export default function ChangeForm() {
  const navigate = useNavigate();
  const [isDone, setIsDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const onSubmit = async (values) => {
    console.log("Success:", values);
    setLoading(true);
    try {
      await changePassword({
        variables: {
          changePasswordInput: {
            newPassword: values.newPassword,
            confirmNewPassword: values.confirmNewPassword,
          },
        },
      });
      message.success("Password has been updated");
      setIsDone(true);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };
  const goHome = () => {
    navigate("/");
  };
  return (
    <div>
      {!isDone ? (
        <div>
          {(!userInfo.user || !userInfo.user.lastLoginAt) && (
            <Alert
              message="You've logged in successfully!"
              description="Please set your own password."
              type="success"
              showIcon
            />
          )}
          <br />
          <div className="form-title">Change Password</div>
          <Form
            name="changeForm"
            layout="vertical"
            size="large"
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
          >
            <Form.Item
              label="New password"
              name="newPassword"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Input your password" />
            </Form.Item>
            <Form.Item
              label="Re-enter your new password"
              name="confirmNewPassword"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Input your password" />
            </Form.Item>
            <br />
            <Form.Item wrapperCol={{ span: 12 }}>
              <Button type="primary" block loading={loading} htmlType="submit">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div>
          <Result
            status="success"
            title="New password is saved successfully!"
            subTitle="You'll be automatically directed to the home page. Please click the following link if you are not directed to home page."
            extra={[
              <Button
                type="primary"
                size="large"
                key="changeHome"
                onClick={goHome}
              >
                Go to Home page
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
}
