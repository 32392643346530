/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 240px;
  left: 0px;
  top: 56px;
  bottom: 0px;
  background: ${({ theme }) => theme.background1};
  border-right: 1px solid ${({ theme }) => theme.black05};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 24px;

  .navbar{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .navItem{
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    font-family: 'SF Pro Display';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.primaryDark};
    padding: 8px;
    padding-inline-start: 16px;
  }

  .selectedItem{
    background: ${({ theme }) => theme.primaryLight};
    color: ${({ theme }) => theme.primaryPressed};
  }

  .settings{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .settingItem{
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    font-family: 'SF Pro Display';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.primaryDark};
    padding: 8px;
    padding-inline-start: 16px;
  }
`;
