import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "./style";
import U from "../../assets/U.png";
import { Button, Form, Input, message } from "antd";
import { gql, useMutation } from "@apollo/client";

const SIGN_UP_MUTATION = gql`
  mutation signUpUser($input: LoginUserInput!) {
    signUp(signUpUserInput: $input) {
      refreshToken
      accessToken
      idToken
      user {
        _id
        name
      }
    }
  }
`;

export default function SignUpPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [signUpUser] = useMutation(SIGN_UP_MUTATION);
  const onFinish = async (values) => {
    console.log("Success:", values);
    setLoading(true);
    try {
      await signUpUser({ variables: { input: values } });
      message.success("Sign up successfully");
      navigate("/Login");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  return (
    <Container>
      <img src={U} alt="U" className="bg-u" />
      <div className="loginPage">
        <div className="loginLogo">
          <div className="logo1">UVITA</div>
          <div className="logo2">PRO</div>
        </div>
        <div className="loginBox">
          <div className="loginBoxTitle">Sign Up</div>
          <Form
            name="loginForm"
            layout="vertical"
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "The input is not valid E-mail" },
              ]}
            >
              <Input placeholder="Input your email" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Input your password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm your password" />
            </Form.Item>
            <div className="flex-between">
              <Link to={`/Login`}>
                <div className="forget-pwd">Sign In</div>
              </Link>
              <Link to={`/ResetPwd?type=forget`}>
                <div className="forget-pwd">Forgot password</div>
              </Link>
            </div>

            <Form.Item wrapperCol={{ span: 12 }}>
              <Button type="primary" block loading={loading} htmlType="submit">
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Container>
  );
}
