import { createSlice } from "@reduxjs/toolkit";

const localLoginInfo = localStorage.getItem("uvita-loginInfo");
const localUserInfo = localStorage.getItem("uvita-userInfo");
export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    loginInfo: (localLoginInfo && JSON.parse(localLoginInfo)) || {},
    userInfo: (localUserInfo && JSON.parse(localUserInfo)) || {},
  },
  reducers: {
    setLoginInfo: (state, action) => {
      state.loginInfo = action.payload;
      localStorage.setItem(
        "uvita-loginInfo",
        JSON.stringify(action.payload) || ""
      );
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem(
        "uvita-userInfo",
        JSON.stringify(action.payload) || ""
      );
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { setLoginInfo, setUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;
