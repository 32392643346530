/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1600px;
  width: calc(100vw);
  height: calc(100vh);
  margin-left: -240px;
  margin-top: -56px;

  .header {
    position: fixed;
    height: 56px;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 10;
    background: ${({ theme }) => theme.primaryDark};
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.boxShadow10};

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 38px 10px 24px;

    .header-logo {
      cursor: pointer;
      color: #fff;
      font-size: 24px;
      .main {
        margin-right: 10px;
      }
      .sub {
        font-weight: 100;
      }
    }
  }

  .form {
    padding-top: 150px;
    .form-box {
      width: 600px;
      margin: 0 auto;
      .form-title {
        font-size: 36px;
      }
    }
  }
`;
