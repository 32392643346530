import axios from "axios";
import store from "../store";
import { message } from "antd";

// axios 配置
const ajax = {
  baseURL: "https://jw5vqew5s5.execute-api.us-east-1.amazonaws.com/Stage/",
  timeout: 30 * 60 * 1000,
  method: "post",
  responseType: "json",
  headers: {
    "content-type": "application/json; charset=utf-8",
  },
  crossDomain: true,
};

const instance = axios.create(ajax);

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    const storeData = store.getState();
    const token =
      storeData.userInfo.loginInfo && storeData.userInfo.loginInfo.idToken;
    config.headers["Authorization"] = "Bearer " + token;
    if (
      config.method === "post" &&
      config.headers["Content-Type"] === "application/x-www-form-urlencoded"
    ) {
      config.data = JSON.stringify(config.data);
    }
    // get参数编码
    if (config.method === "get" && config.params) {
      let url = config.url;
      url += "?";
      const keys = Object.keys(config.params);
      for (const key of keys) {
        if (config.params[key] !== void 0 && config.params[key] !== null) {
          url += `${key}=${encodeURIComponent(config.params[key])}&`;
        }
      }
      url = url.substring(0, url.length - 1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  async (response) => {
    if (response.config.responseType === "blob") {
      // 如果是文件流，直接过
      return response;
    } else if (response.data.res) {
      // const hintFlag =
      //   response.config.headers && response.config.headers.isHint;
      // if (hintFlag || hintFlag === undefined) {
      //   message.success(response.data.msg);
      // }
      return response.data.res;
    } else if (response.data.code === 401) {
      // TOKEN过期执行登出
      message.error(response.data.msg);
      window.location.href = window.location.origin + "/login";
    } else {
      message.error(response.data.msg || "Something wrong");
      return Promise.reject(new Error(response.data.msg || "Error"));
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    message.error(error.message);
    return Promise.reject(error);
  }
);

function request({
  url,
  method,
  params,
  data,
  headersType,
  responseType,
  isHint,
}) {
  return instance({
    url,
    method,
    params,
    data,
    responseType,
    headers: {
      "Content-Type": headersType,
      isHint,
    },
  });
}

export default request;
