import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Table,
  Select,
  Tag,
  Popover,
  Input,
  Modal,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  LinkOutlined,
  MoreOutlined,
  SearchOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Container } from "./style";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { setQuoteData } from "../../../store/features/CreateQuote";
const { confirm } = Modal;

const LIST_QUOTES = gql`
  query ListQuotes(
    $filters: ListQuoteInput
    $args: PaginationArgs
    $sortBy: SortByArgs
  ) {
    listQuotes(filters: $filters, args: $args, sortBy: $sortBy) {
      count
      limit
      offset
      total
      quotes {
        _id
        content
        isBookmarked
        status
        link
        publishedAt
        scheduledAt
        visibility
        coach {
          _id
          name
          email
          belief
          about
          experience
        }
      }
    }
  }
`;

const UPDATE_QUOTE = gql`
  mutation updateQuote($updateQuoteInput: UpdateQuoteInput!) {
    updateQuote(payload: $updateQuoteInput) {
      _id
    }
  }
`;

const DELETE_QUOTE = gql`
  mutation deleteQuote($_id: String!) {
    deleteQuote(_id: $_id) {
      _id
    }
  }
`;

export default function QuoteManager() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({});
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const initVariables = {
    filters: {
      coach: userInfo.coach._id,
      status: null,
    },
    args: {
      limit: 10,
      offset: 0,
    },
    sortBy: {
      createdAt: -1,
      publishedAt: -1,
      scheduledAt: -1,
    },
  };

  const [getQuotes, { data, refetch, called }] = useLazyQuery(LIST_QUOTES, {
    variables: initVariables,
  });
  const [updateQuote] = useMutation(UPDATE_QUOTE);
  const [deleteQuote] = useMutation(DELETE_QUOTE);

  const statusList = [
    {
      label: "Status: All",
      value: "all",
    },
    {
      label: "Status: Published",
      value: "PUBLISHED",
    },
    {
      label: "Status: Draft",
      value: "DRAFTED",
    },
    {
      label: "Status: Scheduled",
      value: "SCHEDULED",
    },
    {
      label: "Status: Hide",
      value: "HIDDEN",
    },
  ];

  function fixLink(link) {
    // 如果链接以 http:// 或 https:// 开头，则不需要做任何处理
    if (link.startsWith("http://") || link.startsWith("https://")) {
      return link;
    }
    // 否则，添加默认的前缀 http://
    return "http://" + link;
  }

  const columns = [
    {
      title: "Insights",
      dataIndex: "content",
      render: (text, row) => {
        if (row.link) {
          return (
            <a
              href={fixLink(row.link)}
              target="_blank"
              rel="noreferrer"
              className="c-blue"
            >
              <LinkOutlined className="mr-10" />
              {text}
            </a>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, row) => {
        switch (status) {
          case "PUBLISHED":
            return <Tag color="success">{status}</Tag>;
          case "SCHEDULED":
            let formattedDate = "";
            if (row.scheduledAt) {
              const date = new Date(row.scheduledAt);
              const options = {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              };
              formattedDate = new Intl.DateTimeFormat("en-US", options).format(
                date
              );
            }
            return (
              <div>
                <Tag color="warning">{status}</Tag>
                {row.scheduledAt && (
                  <span className="c-gray">
                    <ClockCircleOutlined className="mr-10" />
                    {formattedDate}
                  </span>
                )}
              </div>
            );
          default:
            return <Tag>{status}</Tag>;
        }
      },
    },
    {
      title: "Visible to",
      dataIndex: "visibility",
      render: (text, row) => {
        let result = "-";
        if (row.visibility === "PUBLIC") {
          result = "All";
        }
        if (row.visibility === "PRIVATE") {
          result = "Subscribers only";
        }
        return result;
      },
    },
    {
      title: "Publish Date",
      dataIndex: "publishedAt",
      // sorter: true,
      render: (text) => {
        const date = new Date(text);
        const options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          // second: "numeric",
        };
        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
          date
        );
        return formattedDate;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (action, row) => {
        return (
          <Popover
            placement="right"
            content={
              <ul>
                <li>
                  <Button
                    type="text"
                    block
                    className="text-left"
                    onClick={() => onEdit(row)}
                  >
                    Edit
                  </Button>
                </li>
                <li>
                  {row.status !== "HIDDEN" && (
                    <Button
                      type="text"
                      block
                      className="text-left"
                      onClick={() => onHide(row)}
                    >
                      Hide
                    </Button>
                  )}
                </li>
                <li>
                  {row.status === "HIDDEN" && (
                    <Button
                      type="text"
                      block
                      className="text-left"
                      onClick={() => onUnhide(row)}
                    >
                      Unhide
                    </Button>
                  )}
                </li>
                <li>
                  <Button
                    type="text"
                    block
                    className="text-left"
                    onClick={() => onDelete(row)}
                  >
                    Delete
                  </Button>
                </li>
              </ul>
            }
          >
            <Button
              type="text"
              icon={<MoreOutlined style={{ fontSize: "16px" }} />}
            ></Button>
          </Popover>
        );
      },
    },
  ];

  useEffect(() => {
    setTableParams(initVariables);
  }, []);

  useEffect(() => {
    getData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (data?.listQuotes?.quotes) {
      setList(data.listQuotes.quotes);
    } else {
      setList([]);
    }
    setTotal(data?.listQuotes?.total);
  }, [data]);

  const getData = async () => {
    if (!tableParams.args || !tableParams.args.limit) {
      return;
    }
    setLoading(true);
    // if (called) {
    await refetch(tableParams);
    // } else {
    //   await getQuotes(tableParams);
    // }
    setLoading(false);
  };
  const onSearch = (val) => {
    let param = {
      filters: Object.assign({}, tableParams.filters, {
        content: val.target.value,
      }),
      args: tableParams.args,
    };
    if (!val.target.value) {
      delete param.filters.content;
    }
    setTableParams(param);
  };
  const onStatusChange = (val) => {
    let param = { filters: tableParams.filters, args: tableParams.args };
    if (val === "all") {
      param.filters.status = null;
    } else if (val) {
      param.filters.status = val;
    }
    setTableParams(param);
  };

  const onTableChange = (pagination, filters, sorter) => {
    console.log(pagination);
    setTableParams({
      filters: tableParams.filters,
      args: {
        limit: pagination.pageSize,
        offset: pagination.pageSize * (pagination.current - 1),
      },
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.args?.limit) {
      setList([]);
    }
  };

  // Actions
  const onEdit = (data) => {
    dispatch(setQuoteData(data));
    navigate("/EditQuote");
  };
  const onHide = (data) => {
    confirm({
      title: `Hide the insight "${data.content}"?`,
      content: "Are you sure to hide this insight",
      okText: "Yes, hide this insight",
      okType: "danger",
      cancelText: "Cancel",
      async onOk() {
        const param = {
          _id: data._id,
          status: "HIDDEN",
        };
        await updateQuote({
          variables: {
            updateQuoteInput: param,
          },
        });
        message.success(`Insight "${data.content}" is hidden from the app!`);
        getData();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onUnhide = (data) => {
    confirm({
      title: `Unhide the insight "${data.content}"?`,
      content: "Are you sure to unhide this insight",
      okText: "Yes, unhide this insight",
      okType: "danger",
      cancelText: "Cancel",
      async onOk() {
        const param = {
          _id: data._id,
          status: "PUBLISHED",
        };
        await updateQuote({
          variables: {
            updateQuoteInput: param,
          },
        });
        message.success(
          `Insight "${data.content}" is unhidden adn available in the app!`
        );
        getData();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onDelete = (data) => {
    confirm({
      title: `Delete the insight "${data.content}"?`,
      content:
        "Deleting this insight will permanently remove it from your account. Are you sure to delete this insight?",
      okText: "Yes, delete this insight",
      okType: "danger",
      cancelText: "Cancel",
      async onOk() {
        await deleteQuote({
          variables: {
            _id: data._id,
          },
        });
        message.success(`Insight "${data.content}" is deleted successfully!`);
        getData();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Container>
      <div className="flex-between">
        <Input
          size="large"
          placeholder="Search"
          bordered={false}
          prefix={
            <SearchOutlined style={{ fontSize: "20px", marginRight: "10px" }} />
          }
          className="table-search"
          onPressEnter={onSearch}
        />
        <div className="table-filter">
          <Select
            defaultValue="Status: All"
            style={{ width: 150 }}
            onChange={onStatusChange}
            options={statusList}
          />
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={list}
        pagination={{ total }}
        loading={loading}
        onChange={onTableChange}
      />
    </Container>
  );
}
